import * as Types from '../../common/graphql/types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SearchProductsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Float']['input'];
  skip: Types.Scalars['Float']['input'];
  sortOrder: Types.Scalars['Float']['input'];
  category?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter: Types.GetProductsFilter;
  sortFactor: Types.GetProductsSortFactor;
  query?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SearchProductsQuery = { __typename?: 'Query', searchProducts: Array<{ __typename?: 'Product', createdAt: any, id: string, name: string, price: number, quantity: number, slug: string, updatedAt: any, discountPercent?: number | null, searchHighlights?: string | null, pictures: Array<{ __typename?: 'ProductPicture', large: string, small: string }> }> };

export const SearchProductsDocument = gql`
    query searchProducts($limit: Float!, $skip: Float!, $sortOrder: Float!, $category: String, $filter: GetProductsFilter!, $sortFactor: GetProductsSortFactor!, $query: String) {
  searchProducts(
    sortFactor: $sortFactor
    sortOrder: $sortOrder
    category: $category
    filter: $filter
    limit: $limit
    skip: $skip
    query: $query
  ) {
    createdAt
    id
    name
    pictures {
      large
      small
    }
    price
    quantity
    slug
    updatedAt
    discountPercent
    searchHighlights
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchProductsGQL extends Apollo.Query<SearchProductsQuery, SearchProductsQueryVariables> {
    document = SearchProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }